type CalendarVariables = {
  [key in
    | 'dateWidth'
    | 'daysWidth'
    | 'dateHeight'
    | 'daysHeight'
    | 'switcherSelect'
    | 'sundayColor'
    | 'saturdayColor'
    | 'holidayColor'
    | 'currentColor'
    | 'otherColor'
    | 'daysText']: string | string[];
};

const calendars: CalendarVariables = {
  dateWidth: '14.2%',
  daysWidth: '14.2% * 7',
  dateHeight: '60px',
  daysHeight: '60px * 5',
  switcherSelect: '#0086d3',
  sundayColor: '#eb3b78',
  saturdayColor: '#1da8f2',
  holidayColor: '#eb3b78',
  currentColor: '#222',
  otherColor: '#ddd',
  daysText: ['日', '月', '火', '水', '木', '金', '土'],
};

type GrayScaleVariables = {
  [key in 'gs900' | 'gs700' | 'gs600' | 'gs400' | 'gs300' | 'gs200' | 'gs100']: string;
};

const grayScales: GrayScaleVariables = {
  gs900: '#454552',
  gs700: '#757575',
  gs600: '#9c9c9c',
  gs400: '#c6c9c3',
  gs300: '#dfe1e6',
  gs200: '#ebedf0',
  gs100: '#f5f7fa',
};

type ColorVariables = {
  [key in
    | 'primary'
    | 'success'
    | 'warning'
    | 'danger'
    | 'calendar'
    | 'white'
    | 'black'
    | 'yellow'
    | 'light'
    | 'dark'
    | 'graph1'
    | 'graph2'
    | 'graph3'
    | 'graph4'
    | 'link'
    | 'contest'
    | 'mvp'
    | 'mvpLink']: string;
};

const colors: ColorVariables = {
  primary: '#0087d9',
  success: '#5ec206',
  warning: '#f5a700',
  danger: '#f50010',
  calendar: '#0086d3',
  white: '#fff',
  black: '#000',
  yellow: '#fcf16e',
  light: grayScales.gs100,
  dark: grayScales.gs900,
  graph1: '#00b1df',
  graph2: '#1b5bf0',
  graph3: '#bd2fd9',
  graph4: '#e3d42d',
  link: '#00ace0',
  contest: '#f50010',
  mvp: '#fba848',
  mvpLink: '#ff4081'
};

type BreakPointVariables = {
  [key in 'desktop' | 'tablet' | 'phone' | 'calender']: string;
};

const breakPoints: BreakPointVariables = {
  desktop: '840px',
  tablet: '600px',
  phone: '480px',
  calender: '1024px',
};

type GradationVariables = {
  [key in
    | 'primary'
    | 'primaryVertical'
    | 'primaryHover'
    | 'secondary'
    | 'graph1'
    | 'graph2'
    | 'graph3'
    | 'graph4'
    | 'danger'
    | 'dangerHover'
    | 'material'
    | 'materialHover'
    | 'forum'
    | 'loading'
    | 'emptyArticleImage']: string;
};

const gradations: GradationVariables = {
  primary: 'linear-gradient(-90deg, #00a8e6 0%, #0086d3 100%)',
  primaryVertical: 'linear-gradient(0deg, #00a8e6 0%, #0086d3 100%)',
  primaryHover: 'linear-gradient(90deg, darken(#00a8e6, 16%) 0%, darken(#0086d3, 16%) 100%)',
  secondary: 'linear-gradient(-90deg, #3023ae 0%, #976dd6 100%)',
  graph1: 'linear-gradient(-90deg, #00dae6 0%, #0087d9 100%)',
  graph2: 'linear-gradient(-90deg, #09f 0%, #351be0 100%)',
  graph3: 'linear-gradient(90deg, #872de0 0%, #f230d2 100%)',
  graph4: 'linear-gradient(-90deg, #e0c22d 0%, #e6e62e 100%)',
  danger: 'linear-gradient(90deg, #f54e59 0%, #f50010 100%)',
  dangerHover: 'linear-gradient(90deg, darken(#f54e59, 25%) 0%, darken(#f50010, 16%) 100%)',
  material: 'radial-gradient(33% 29%, #0099f2 33%, #8d2de0 100%)',
  materialHover: 'linear-gradient(90deg, darken(#0099f2, 16%) 0%, darken(#8d2de0, 16%) 100%)',
  forum: 'linear-gradient(-225deg, #d7ecf5, #baddf5)',
  loading: 'linear-gradient( #dedede 0%, #dedede 100%);',
  emptyArticleImage: 'linear-gradient(-225deg,#d7ecf5,#baddf5);'
};

type FontVariables = {
  [key in 'default']: string;
};

const fonts: FontVariables = {
  default:
    '-apple-system, BlinkMacSystemFont, "Roboto", "Helvetica", "Helvetica Neue", "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, Arial, "メイリオ", Meiryo, sans-serif',
};

type BackgroundColorVariables = {
  [key in 'default' | 'primary' | 'success' | 'warning' | 'danger' | 'error' | 'sideMenu' | 'sideMenuSelected' | 'awardsPanel' | 'epidemicsound']: string;
};

const backgroundColors: BackgroundColorVariables = {
  default: '#fafafa',
  primary: '#e1f0fa',
  success: '#e9f7ea',
  warning: '#faf4e1',
  danger: '#fae1e3',
  error: '#f2f4f7',
  sideMenu: 'rgba(89, 89, 89, 0.90)',
  sideMenuSelected: 'rgba(111, 111, 111, 0.95)',
  awardsPanel: '#fff9eb',
  epidemicsound: '#D7464B'
};

type BoxShadowsVariables = {
  [key in 'article' | 'button' | 'forumTopic']: string;
};

const boxShadows: BoxShadowsVariables = {
  article: '0 4px 16px rgb(42 42 82 / 4%), 0 1px 6px rgb(42 42 82 / 4%)',
  button: '0 1px 6px 0 rgb(42 42 82 / 8%), 0 2px 16px 0 rgb(42 42 82 / 8%)',
  forumTopic: '0 0 1rem rgb(0 0 0 / 80%)',
};

type AnimationVariables = {
  [key in 'hover']: string;
};

const animations: AnimationVariables = {
  hover: 'all 0.05s 0s ease',
};

type ZIndexVariables = {
  [key in 'loading' | 'sideMenu' | 'mobileMenu' | 'menu' | 'subMenu' | 'basicMenu' | 'modal' | 'flashMessage']: number;
};

const zIndexes: ZIndexVariables = {
  loading: 80,
  sideMenu: 90,
  mobileMenu: 100,
  menu: 100,
  subMenu: 90,
  basicMenu: 100,
  modal: 150,
  flashMessage: 200,
};

type HeightVariables = {
  [key in 'headerMenu' | 'footerMenu' | 'forumTopicThumbnail']: string;
};

const heights: HeightVariables = {
  headerMenu: '60px',
  footerMenu: '72px',
  forumTopicThumbnail: '240px',
};

const mobileHeights: HeightVariables = {
  headerMenu: '60px',
  footerMenu: '204px',
  forumTopicThumbnail: '240px',
};

export {
  colors,
  grayScales,
  breakPoints,
  gradations,
  fonts,
  backgroundColors,
  boxShadows,
  animations,
  zIndexes,
  heights,
  mobileHeights,
  calendars,
};
