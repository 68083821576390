import styled, { css } from 'styled-components';
import { mediaDown } from 'styles/mixins';

const ErrorIconStyled = styled.img`
    ${mediaDown('desktop', css`
      width: 100px;
    `)}

    width: 150px;
  `,
  ErrorIcon = (): React.ReactElement => (
    <ErrorIconStyled src='/common/error-icon.png' className='error-icon' alt='error_icon' />
  );

export default ErrorIcon;
