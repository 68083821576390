import FlashMessage from 'components/shareds/FlashMessage';
import { useFlashMessageState, FlashMessageColorType } from 'contexts/FlashMessageContext';
import { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import styled, { css } from 'styled-components';
import { mediaDown } from 'styles/mixins';
import { zIndexes } from 'styles/variables';

const FLASH_MESSAGE_DEFAULT_POSITION = -1000,
  FlashMessageMovingStyled = styled.div`
    .flash-message-moving {
      position: fixed;
      z-index: ${zIndexes.flashMessage};
      width: 100%;
      padding: 0 20%;
      box-sizing: border-box;

      ${mediaDown(
    'calender',
    css`
        padding: 0 10%;
                  `
  )}
    }

    @keyframes show {
      0% {
        top: ${`${FLASH_MESSAGE_DEFAULT_POSITION}px`};
      }
      100% {
        top: 50px;
      }
    }

    .show {
      animation-name: show;
      animation-duration: 0.3s;
      animation-timing-function: ease;
      animation-fill-mode: forwards;
    }

    .hide {
      top: ${`${FLASH_MESSAGE_DEFAULT_POSITION}px`};
    }
  `,
  FlashMessageMoving: React.FC = () => {
    const [moving, setMoving] = useState(false),
      { flashMessage, setFlashMessage } = useFlashMessageState(),
      router = useRouter(),
      removeFlashMessageParams = () => {
        const newQuery = router.query;
        delete newQuery.color;
        delete newQuery.message;

        router.replace({
          pathname: router.pathname,
          query: newQuery,
        });
      };

    useEffect(() => {
      if (flashMessage.message === '' || moving) {
        return
      }

      setMoving(true)
    }, [flashMessage]);

    useEffect(() => {
      if (!router.isReady) {
        return
      }

      const { color, message } = router.query;
      if (!color || !message) {
        return
      }

      setFlashMessage({
        color: color as FlashMessageColorType,
        message: message as string
      })

      removeFlashMessageParams()
    }, [router.isReady])

    const resetFlashMessage = () => {
      setMoving(false)
    }

    return (
      <FlashMessageMovingStyled>
        <div className={['flash-message-moving', moving ? 'show' : 'hide'].join(' ')}>
          <FlashMessage
            type={flashMessage.color}
            message={flashMessage.message}
            didClickCloseButton={resetFlashMessage}
          />
        </div>
      </FlashMessageMovingStyled>
    );
  };

export default FlashMessageMoving;
