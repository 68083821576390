import 'styles/base.css';
import ApiClientErrorBoundary from 'components/errorrBoundaries/ApiClientErrorBoundary';
import { NextPage } from 'next';
import type { AppContext, AppProps } from 'next/app';
import { ReactElement, ReactNode } from 'react';
import portaToCreasAdaptor from 'utils/portaToCreasAdaptor';

type NextPageWithLayout<P = {}, S = {}> = NextPage<P, S> & {
  getLayout?: (page: ReactElement) => ReactNode;
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

const MyApp = ({ Component, pageProps }: AppPropsWithLayout) => {
  const getLayout = Component.getLayout ?? ((page) => page);

  if (typeof window === 'undefined') {
    return null;
  }

  return portaToCreasAdaptor() ? (
    <ApiClientErrorBoundary>
      { getLayout(<Component {...pageProps} />) }
    </ApiClientErrorBoundary>
  ) : <></>;
};

MyApp.getInitialProps = async ({ Component, ctx }: AppPropsWithLayout & AppContext) => {
  const pageProps = Component.getInitialProps ? await Component.getInitialProps(ctx) : {};
  return { pageProps };
}

export type { NextPageWithLayout };

export default MyApp;
