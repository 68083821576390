import ErrorIcon from 'components/atoms/ErrorIcon';
import FlatButton from 'components/atoms/button/FlatButton';
import ErrorLayout from 'components/layouts/ErrorLayout';
import styled, { css } from 'styled-components';
import { fontSize, mediaDown } from 'styles/mixins';
import { grayScales } from 'styles/variables';

const Custom403Styled = styled.div`
    .error-403 {
      ${mediaDown(
    'desktop',
    css`
          ${fontSize(12)}
        `
  )}

      color: ${grayScales.gs900};
      text-align: center;
      ${fontSize(14)}

      &__title {
        ${mediaDown(
    'desktop',
    css`
            ${fontSize(20)}
          `
  )}

        ${fontSize(32)}
      font-weight: 700;
        margin: 3rem 0;
      }

      &__message {
        margin: 3rem 0;
      }

      &__button {
        display: flex;
        justify-content: center;
      }
    }
  `,
  Custom403 = () => (
    <ErrorLayout>
      <Custom403Styled>
        <div className='error-403'>
          <ErrorIcon />
          <p className='error-403__title'>このページへのアクセスは禁止されています。</p>
          <p className='error-403__message'>ブラウザバックまたは下記ボタンよりお戻りください。</p>
          <div className='error-403__button'>
            <FlatButton title='トップページに戻る' path='/' />
          </div>
        </div>
      </Custom403Styled>
    </ErrorLayout>
  );
export default Custom403;
