import ErrorIcon from 'components/atoms/ErrorIcon';
import FlatButton from 'components/atoms/button/FlatButton';
import ErrorLayout from 'components/layouts/ErrorLayout';
import styled, { css } from 'styled-components';
import { fontSize, mediaDown } from 'styles/mixins';
import { grayScales } from 'styles/variables';

const Custom404Styled = styled.div`
    .error-404 {
      ${mediaDown('desktop', css`
        ${fontSize(12)}
      `)}

      color: ${grayScales.gs900};
      text-align: center;
      ${fontSize(14)}

      &__title {
        ${mediaDown('desktop', css`
          ${fontSize(20)}
        `)}
        
        ${fontSize(32)}
        font-weight: 700;
        margin: 3rem 0;
      }

      &__message {
        margin: 3rem 0;
      }

      &__button {
        display: flex;
        justify-content: center;
      }
    }
  `,
  Custom404 = () => (
    <ErrorLayout>
      <Custom404Styled>
        <div className='error-404'>
          <ErrorIcon />
          <p className='error-404__title'>ページが見つかりませんでした</p>
          <p className='error-404__message'>ブラウザバックまたは下記ボタンよりお戻りください。</p>
          <div className='error-404__button'>
            <FlatButton title='トップページに戻る' path='/' />
          </div>
        </div>
      </Custom404Styled>
    </ErrorLayout>
  );

export default Custom404;
