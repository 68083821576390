import CustomErrorComponent from 'pages/_error';
import { ApiClientError } from 'utils/ApiClientError';

export type ApiClientErrorFallbackProps = {
  error: ApiClientError<any>;
}

const ApiClientErrorFallback: React.FC<ApiClientErrorFallbackProps> = (props) => (
  <CustomErrorComponent statusCode={ props.error.getStatus() } />
);

export default ApiClientErrorFallback;
