import { css } from 'styled-components';
import { CSSProp } from 'styled-components';
import { breakPoints } from 'styles/variables';

type DeviceType = 'desktop' | 'tablet' | 'phone' | 'calender' | 'article';

const fontSize = (size: number = 24, base: number = 10) => css`
  font-size: ${size / base + 'rem'};
`;

const mediaDown = (device: DeviceType, content: CSSProp): CSSProp => {
  if (device == 'desktop') {
    return css`
      @media only screen and (max-width: ${breakPoints.desktop}) {
        ${content}
      }
    `;
  }

  if (device == 'tablet') {
    return css`
      @media only screen and (max-width: ${breakPoints.tablet}) {
        ${content}
      }
    `;
  }

  if (device == 'phone') {
    return css`
      @media only screen and (max-width: ${breakPoints.phone}) {
        ${content}
      }
    `;
  }

  if (device == 'calender') {
    return css`
      @media only screen and (max-width: ${breakPoints.calender}) {
        ${content}
      }
    `;
  }

  return css``;
};

export { fontSize, mediaDown };
