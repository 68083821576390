import { useRouter } from 'next/router';
import styled from 'styled-components';

const LogoCreasHeaderStyled = styled.span`
  background: url(/svg/logo-creas.svg) 0 0 no-repeat;
  background-size: 100% auto;
  display: inline-block;
  height: 28px;
  text-indent: -9999px;
  width: 77px;

  &.use-link {
    cursor: pointer;
  }
`;

interface LogoCreasHeaderInterface {
  noLink?: boolean;
};

const LogoCreasHeader = (props: LogoCreasHeaderInterface): React.ReactElement => {
  const router = useRouter()

  return (
    <LogoCreasHeaderStyled
      className={props.noLink ? '' : 'use-link'}
      onClick={() => {
        if (props.noLink) {
          return
        }
        router.push('/')
      }}
    >
      UUUM
    </LogoCreasHeaderStyled>
  );
};

export default LogoCreasHeader;
