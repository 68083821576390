import {
  HTTP_BAD_REQUEST,
  HTTP_ERROR_CSRF,
  HTTP_FORBIDDEN,
  HTTP_FOUND,
  HTTP_NOT_FOUND,
  HTTP_UNAUTHORIZED
} from 'constants/httpStatusConstants';
import { ApiResponseErrorType, ApiResponseType } from 'types/apiResponseTypes';

class ApiClientError<T> {
  status: number;

  message: string | null;

  result: null | T;

  errors: ApiResponseErrorType;

  constructor (props: Partial<ApiClientError<T>> = {}) {
    this.status = 0;
    this.message = '';
    this.result = null;
    this.errors = null;

    Object.assign(this, props)
  }

  getStatus(): number {
    return this.status;
  }

  getMessage(): null | string {
    return this.message;
  }

  getErrors(): ApiResponseErrorType {
    return this.errors;
  }

  getResult(): T | null {
    return this.result;
  }

  needReLogin(): boolean {
    return this.status === HTTP_UNAUTHORIZED || this.status === HTTP_ERROR_CSRF;
  }

  httpForbidden(): boolean {
    return this.status === HTTP_FORBIDDEN;
  }

  httpBadRequest(): boolean {
    return this.status === HTTP_BAD_REQUEST;
  }

  httpNotFound(): boolean {
    return this.status === HTTP_NOT_FOUND;
  }

  httpFound(): boolean {
    return this.status === HTTP_FOUND;
  }

  getResponse(): ApiResponseType<T> {
    return {
      errors: this.errors,
      message: this.message,
      result: this.result,
      status: this.status
    };
  }
}

export { ApiClientError };
