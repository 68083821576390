import { useState } from 'react';

const useModal = () => {
  const [show, setShow] = useState(false);
  const [modalContent, setModalContent] = useState<JSX.Element>(<></>);

  return {
    closeModal: () => setShow(false),
    modalContent,
    openModal: () => setShow(true),
    setModalContent,
    show,
  }
}

export default useModal;
