import { useRouter } from 'next/router';

/**
 * ボタンの基底コンポーネント
 */

export interface BaseButtonInterface {
  // ボタンのタイトル
  title: string | React.ReactElement;
  // パスがある場合はリンクとして機能する
  path?: string;
  // 無効の場合は全ての操作を受け付けない
  disabled?: boolean;
  // formのsubmitとして機能するか（デフォルトは単なるボタン）
  isSubmit?: boolean;
  // クリック後に呼ばれるコールバック
  didClickButton?: (button: BaseButtonInterface) => void;
}

export const BaseButton = <T extends BaseButtonInterface>(props: T) => {
  const router = useRouter();

  return (
    <button
      type={props.isSubmit ? 'submit' : 'button'}
      onClick={(e) => {
        if (props.disabled) {
          e.preventDefault()
          return
        }

        if (props.path) {
          router.push(props.path)
        }

        props.didClickButton?.(props)
      }}
    >
      {props.title}
    </button>
  )
}
