import React, { useState, useContext, createContext } from 'react';

const PageTitleContext = createContext({
  pageTitle: '',
  setPageTitle: (key: string) => {},
});

const PageTitleContextProvider = (props: { children: React.ReactElement }) => {
  const [pageTitle, setPageTitle] = useState('');

  const pageTitleData = {
    pageTitle: pageTitle,
    setPageTitle: setPageTitle,
  };

  return <PageTitleContext.Provider value={pageTitleData}>{props.children}</PageTitleContext.Provider>;
};

const getPageTitleState = () => useContext(PageTitleContext);

export default PageTitleContext;

export { PageTitleContextProvider, PageTitleContext, getPageTitleState };
