import React, { useState, useContext, createContext } from 'react';
import { HeaderMenuResultType, HeaderMenuItemType } from 'types/apiResponseTypes';

const InitializeHeaderMenu = {
  menu: [] as HeaderMenuItemType[],
  basicMenu: [] as HeaderMenuItemType[],
  thumbnailImageUrl: '',
  channelId: '',
  channelName: '',
};

const HeaderMenuContext = createContext({
  headerMenu: InitializeHeaderMenu,
  setHeaderMenu: (arg1: HeaderMenuResultType) => {},
});

const HeaderMenuContextProvider = (props: { children: React.ReactElement }) => {
  const [headerMenu, setHeaderMenu] = useState<HeaderMenuResultType>(InitializeHeaderMenu);

  const headerMenuData = {
    headerMenu: headerMenu,
    setHeaderMenu: setHeaderMenu,
  };

  return <HeaderMenuContext.Provider value={headerMenuData}>{props.children}</HeaderMenuContext.Provider>;
};

const getHeaderMenuState = () => useContext(HeaderMenuContext);

export default HeaderMenuContext;

export { HeaderMenuContextProvider, getHeaderMenuState };
