import {
  HTTP_UNAUTHORIZED,
  HTTP_FOUND,
  HTTP_FORBIDDEN,
  HTTP_ERROR_CSRF,
  HTTP_BAD_REQUEST,
  HTTP_NOT_FOUND
} from 'constants/httpStatusConstants';
import axios, { AxiosError, AxiosResponse, AxiosInstance } from 'axios';
import { ApiRequestInterface, CustomFormRequest } from 'types/apiRequests';
import { ApiResponseErrorType, ApiResponseType } from 'types/apiResponseTypes';

export const axiosInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL,
  withCredentials: true
})

interface ApiClientObject {
  [arg1: string]: any;
}

class ApiClient<T> implements ApiClientObject {
  axios: AxiosInstance;

  status: number;

  message: string | null;

  result: T | null;

  errors: ApiResponseErrorType;

  constructor() {
    this.axios = axiosInstance;
    this.status = 0;
    this.message = '';
    this.result = null;
    this.errors = null;
  }

  getStatus(): number | void {
    return this.status;
  }

  getMessage(): null | string {
    return this.message;
  }

  getErrors(): ApiResponseErrorType {
    return this.errors;
  }

  getResult(): T | null {
    return this.result;
  }

  needReLogin(): boolean {
    return this.status === HTTP_UNAUTHORIZED || this.status === HTTP_ERROR_CSRF;
  }

  httpForbidden(): boolean {
    return this.status === HTTP_FORBIDDEN;
  }

  httpBadRequest(): boolean {
    return this.status === HTTP_BAD_REQUEST;
  }

  httpNotFound(): boolean {
    return this.status === HTTP_NOT_FOUND;
  }

  httpFound(): boolean {
    return this.status === HTTP_FOUND;
  }

  clear(): void {
    this.status = 0;
    this.message = '';
    this.result = null;
  }

  getResponse(): ApiResponseType<T> {
    return { 
      errors: this.errors,
      message: this.message,
      result: this.result,
      status: this.status,
    };
  }

  requestV2<U extends ApiRequestInterface>(request: U): Promise<ApiResponseType<T>> {
    const requestData = request instanceof CustomFormRequest ? request.getFormData() : request;

    return this.axios.request({
      data: ['POST', 'PUT'].includes(request.getMethod()) ? requestData : {},
      method: request.getMethod(),
      params: ['GET', 'DELETE'].includes(request.getMethod()) ? requestData : {},
      url: request.getPath(),
    })
      .then((response: AxiosResponse<ApiResponseType<T>>) => {
        const { data, status } = response;

        this.status = status;
        this.message = data.message;
        this.errors = data.errors;
        this.result = data.result;

        return this.getResponse();
      })
      .catch((error: AxiosError<ApiResponseType<T>>) => {
        if (error.response) {
          const { data, status } = error.response;
          this.status = status;
          this.message = data.message;
          this.errors = data.errors;
          this.result = data.result;
        }
        return this.getResponse();
      });
  }
}

export { ApiClient };

export type { ApiClientObject };
