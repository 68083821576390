import styled from 'styled-components';
import { boxShadows, colors, gradations, grayScales } from 'styles/variables';
import { BaseButton, BaseButtonInterface } from 'components/atoms/button/BaseButton';

type SizeType = 'small' | 'medium' | 'large';
type ButtonStyleType = 'default' | 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'loading';

const bgImage = (type: string) => {
  switch (type) {
  case 'loading': return gradations.loading;
  case 'primary': return gradations.primary;
  case 'secondary': return gradations.secondary;
  case 'danger': return gradations.danger;
  default: return 'none';
  }
}

const width = (type: SizeType) => {
  switch (type) {
  case 'small': return '180px';
  case 'medium': return '240px';
  case 'large': return '320px';
  }
}

const height = (type: SizeType) => {
  switch (type) {
  case 'small': return '32px';
  case 'medium': return '44px';
  case 'large': return '60px';
  }
}

const fontSize = (type: SizeType) => {
  switch (type) {
  case 'small': return '12px';
  case 'medium': return '16px';
  case 'large': return '20px';
  }
}

const FlatButtonStyled = styled.span.attrs((props: {
  type: ButtonStyleType;
  size: SizeType;
}) => props)`
  button {
    box-sizing: border-box;
    text-decoration: none;
    cursor: pointer;
    padding: 6px 20px 8px 20px;
    border-radius: 4px;
    font-weight: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    box-shadow: ${(props) => ((bgImage(props.type) === 'none' || props.type === 'loading') ? '' : boxShadows.button)};
    border: solid ${(props) => (bgImage(props.type) === 'none' ? '1px' : '0')}
      ${(props) => (bgImage(props.type) === 'none' ? grayScales.gs400 : '')};
    color: ${(props) => (bgImage(props.type) === 'none' ? grayScales.gs600 : colors.white)};
    font-size: ${(props) => fontSize(props.size)};
    min-width: ${(props) => width(props.size)};
    height: ${(props) => height(props.size)};
    background-image: ${(props) => bgImage(props.type)};
    background-color: rgba(0, 0, 0, 0);

    :hover {
      opacity: 0.8;
    }
  }
`;

interface FlatButtonInterface extends BaseButtonInterface {
  type?: ButtonStyleType;
  size?: SizeType;
};

const FlatButton = (props: FlatButtonInterface): React.ReactElement => (
  <FlatButtonStyled
    type={props.type ?? 'default'}
    size={props.size ?? 'medium'}
  >
    <BaseButton {...props} />
  </FlatButtonStyled>
);

export default FlatButton;
export { FlatButtonStyled, FlatButtonInterface }
