const mapList: { [key: string]: string; } = {
  '^/channel_info/([^/]+)$': '/channel_info',
  '^/contest/detail/([^/]+)$': '/contest/$1',
  '^/elearning$': '/support/elearning',
  '^/home$': '/',
  '^/material/(bgm|search/audio)/(?!epidemic_sound$)(?!other_items$).+$': '/material/bgm',
  '^/material/(bgm|search/audio)/epidemic_sound$': '/material/epidemic_sound',
  '^/material/bgm/images/search$': '/material/photo',
  '^/material/bgm/other_items$': '/material/channel_art',
  '^/material/search/(joysound|euclid|star_music|star_music_all_bgm)$': '/material/bgm?source=$1',
  '^/material/search/(photo|illustration|video|font)$': '/material/$1',
  '^/material/search/audio$': '/material/bgm',
  '^/material/search/nana$': '/material/bgm?source=nana_music',
  '^/module/([^/]+)$': '/article/$1/list',
  '^/module/([^/]+)/([^/]+)$': '/article/$1/list/$2',
  '^/module/partnership_service$': '/support/partnership_service',
  '^/module/partnership_service/([^/.]+)': '/support/partnership_service/$1',
  '^/module/top_creators_([^/]+)$': '/article/top_creators_service/$1',
  '^/module/top_creators_([^/]+)/([^/]+)$': '/article/top_creators_service/$1/$2',
  '^/page/([^/]+)$': '/article/news/list/$1'
}

const portaToCreasAdaptor = (): boolean => {
  const path: string = window.location.pathname;

  for (const regex in mapList) {
    if (path.match(regex) !== null) {
      window.location.href = path.replace(new RegExp(regex, 'u'), mapList[regex]);
      return false;
    }
  }

  if (path.match('^/admin.*$') !== null) {
    window.location.href = process.env.NEXT_PUBLIC_BACKEND_URL ?? '';
    return false;
  }

  return true;
}

export default portaToCreasAdaptor;