export const HTTP_OK = 200;
export const HTTP_FOUND = 302;
export const HTTP_SEE_OTHER = 303;
export const HTTP_TEMPORARY_REDIRECT = 307;
export const HTTP_BAD_REQUEST = 400;
export const HTTP_UNAUTHORIZED = 401;
export const HTTP_FORBIDDEN = 403;
export const HTTP_NOT_FOUND = 404;
export const HTTP_ERROR_CSRF = 419;
export const HTTP_UNPROCESSABLE_ENTITY = 422;
