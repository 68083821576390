import { useModalDispatch } from 'contexts/ModalContext';
import { useEffect } from 'react';
import styled from 'styled-components';
import { colors, zIndexes } from 'styles/variables';

const ModalStyled = styled.div.attrs((props: { show: boolean }) => ({
  show: props.show,
}))`
  ._modal {
    position: fixed;
    left: 0;
    top: 0;
    display: ${(props) => (props.show ? 'flex' : 'none')};
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    z-index: ${zIndexes.modal};

    &__content {
      display: flex;
      flex-direction: column;
      background-color: ${colors.white};
      border-radius: 5px;
      box-sizing: border-box;
      padding: 30px;
      width: 700px;
      max-width: calc(100% - 30px);
      max-height: calc(100% - 30px);
      position: relative;
    }

    &__close {
      width: 30px;
      height: 30px;
      background: none;
      position: absolute;
      top: 10px;
      right: 10px;
      border: none;
      border-radius: 0;
      outline: none;
      padding: 0;
      cursor: pointer;

      span {
        display: block;
        width: 24px;
        height: 2px;
        background-color: ${colors.black};
        position: absolute;
        top: 50%;
        left: 3px;
        transition: background-color 0.25s;

        &:first-child {
          transform: rotate(45deg);
        }

        &:last-child {
          transform: rotate(-45deg);
        }
      }

      &:hover span {
        background-color: ${colors.primary};
      }
    }
  }
`;

type ModalType = {
  children: React.ReactNode;
  show: boolean;
  // onCloseを指定すると、モーダルの右上に×ボタンが表示される
  onClose?: () => void;
};

const Modal = (props: ModalType) => {
    const handleBackDropClick = (e: React.MouseEvent<HTMLDivElement>) => {
      if (e.target === e.currentTarget) {
        props.onClose?.();
      }
    }

    useEffect(() => {
      document.body.style.overflow = props.show ? 'hidden' : 'unset';

      return () => {
        document.body.style.overflow = 'unset';
      }
    }, [props.show])

    return (
      <ModalStyled show={props.show}>
        <div className='_modal' onClick={handleBackDropClick}>
          <div className='_modal__content'>
            {props.onClose &&
              <button className='_modal__close' onClick={props.onClose}>
                <span />
                <span />
              </button>
            }
            {props.children}
          </div>
        </div>
      </ModalStyled>
    )
  },
  ModalAdaptor = (props: {children: JSX.Element}) => {
    const { setModalContent } = useModalDispatch();

    useEffect(() => {
      setModalContent(props.children);
    }, [props.children]);

    return <></>;
  };

export default Modal;
export { ModalAdaptor };
