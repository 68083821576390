import Loading from 'components/atoms/Loading';
import ApiClientErrorHandler from 'components/errorrBoundaries/ApiClientErrorHandler';
import FlashMessageMoving from 'components/shareds/FlashMessageMoving';
import { FlashMessageContextProvider } from 'contexts/FlashMessageContext';
import { HeaderMenuContextProvider } from 'contexts/HeaderMenuContext';
import { ModalContextProvider } from 'contexts/ModalContext';
import { PageTitleContextProvider, getPageTitleState } from 'contexts/PageTitleContext';
import Head from 'next/head';
import { BrowserRouter } from 'react-router-dom';

const LayoutWrapper = (props: { children?: React.ReactElement }): React.ReactElement => {
    const { pageTitle } = getPageTitleState();

    return (
      <>
        <Head>
          <meta charSet='UTF-8' />
          <meta httpEquiv='X-UA-Compatible' content='IE=edge' />
          <meta property='og:title' content={pageTitle === '' ? '' : `${pageTitle} | ` + 'CREAS'} />
          <meta property='og:type' content='website' />
          <meta property='og:url' content='https://creators.uuum.jp/' />
          <meta property='og:image' content='/ogp.jpg' />
          <meta property='og:site_name' content={pageTitle === '' ? '' : `${pageTitle} | ` + 'CREAS'} />
          <meta property='og:locale' content='ja_JP' />
          <meta property='og:description' content='YouTubeで活躍したいクリエイターのためのネットワークです。' />
          <meta name='description' content='A front-end template that helps you build fast, modern mobile web apps.' />
          <meta name='viewport' content='width=device-width, initial-scale=1.0, minimum-scale=1.0' />
          <meta name='apple-mobile-web-app-capable' content='yes' />
          <meta name='apple-mobile-web-app-status-bar-style' content='black' />
          <meta name='apple-mobile-web-app-title' content={pageTitle === '' ? '' : `${pageTitle} | ` + 'CREAS'} />
          <meta name='mobile-web-app-capable' content='yes' />
          <meta name='msapplication-TileColor' content='#3372DF' />
          <link rel='apple-touch-icon' href='apple-touch-icon.png' />
          <link rel='icon' type='image/x-icon' href='favicon.ico' />
          <title>{pageTitle === '' ? '' : `${pageTitle} | `}CREAS</title>
          <>
            <script async src='https://www.googletagmanager.com/gtag/js?id=UA-66440763-17' />
            <script
              dangerouslySetInnerHTML={{
                __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', 'UA-66440763-17', {
                  page_path: window.location.pathname,
                });`,
              }}
            />
            {/* Google Tag Manager */}
            <script dangerouslySetInnerHTML={{
              __html: `
                (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});
                var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
                j.async=true;j.src= 'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})
                (window,document,'script','dataLayer','GTM-KPDGG8G');
              `,
            }}/>
          </>
        </Head>
        <FlashMessageContextProvider>
          <ApiClientErrorHandler>
            <ModalContextProvider>
              <HeaderMenuContextProvider>
                <>
                  <FlashMessageMoving />
                  <Loading />
                  <BrowserRouter>{props.children}</BrowserRouter>
                </>
              </HeaderMenuContextProvider>
            </ModalContextProvider>
          </ApiClientErrorHandler>
        </FlashMessageContextProvider>
      </>
    );
  },
  Layout = (props: { children?: React.ReactElement }) => (
    <PageTitleContextProvider>
      <LayoutWrapper {...props} />
    </PageTitleContextProvider>
  );
export default Layout;
