import {
  HTTP_BAD_REQUEST,
  HTTP_ERROR_CSRF,
  HTTP_FORBIDDEN,
  HTTP_FOUND,
  HTTP_NOT_FOUND,
  HTTP_UNAUTHORIZED
} from 'constants/httpStatusConstants';
import { AxiosError } from 'axios';
import { useFlashMessageState } from 'contexts/FlashMessageContext';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react'
import { ApiResponseType, RedirectResultType } from 'types/apiResponseTypes';
import { axiosInstance } from 'utils/ApiClient';

const ApiClientErrorHandler = ({ children }: {children: React.ReactNode}) => {
  const router = useRouter();
  const { setFlashMessage } = useFlashMessageState();
  const [isReady, setIsReady] = useState<boolean>(false);

  useEffect(() => {
    axiosInstance.interceptors.response.use(
      response => response,
      // eslint-disable-next-line max-statements
      (error: AxiosError<ApiResponseType<any>>) => {
        const status = error.response?.status ?? 0;
        const response = error.response;
  
        // 401, 419
        if (status === HTTP_UNAUTHORIZED || status === HTTP_ERROR_CSRF) {
          const pathBeforeLogin: string = location.pathname;
  
          if (pathBeforeLogin !== '/user/signin') {
            router.push({
              pathname: '/user/signin',
              query: {
                color: 'danger',
                message: 'ログイン有効期限が切れたため、再ログインして下さい。',
                pathBeforeLogin
              },
            });
          }
          return new Promise(() => {});
        }
  
        // 302
        if (status === HTTP_FOUND && response) {
          const redirectResult: RedirectResultType = response.data.result;

          if (location.pathname !== redirectResult.redirectUrl) {
            router.push({
              pathname: redirectResult.redirectUrl,
              query: {
                color: 'danger',
                message: response.data.message,
                pathBeforeLogin: redirectResult.pathBeforeLogin
              },
            });
          }
          return new Promise(() => {});
        }
  
        // 403
        if (status === HTTP_FORBIDDEN) {
          router.push('/403');
          return new Promise(() => {});
        }
  
        // 404
        if (status === HTTP_NOT_FOUND) {
          router.push('/404');
          return new Promise(() => {});
        }
  
        // 400
        if (status === HTTP_BAD_REQUEST) {
          setFlashMessage({
            color: 'danger',
            message: response?.data.message ?? ''
          })
        }
  
        return Promise.reject(error);
      }
    )
    setIsReady(true);
  }, []);

  if (!isReady) {
    return <></>
  }

  return (
    <>{children}</>
  );
}

export default ApiClientErrorHandler;
