import Router from 'next/router'
import { Component } from 'react';
import { ApiClientError } from 'utils/ApiClientError';
import ApiClientErrorFallback from './ApiClientErrorFallback';

type Props = {};

type State = {
  hasError: boolean;
  error: unknown | null;
};

class ApiClientErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      error: null,
      hasError: false
    };

    this.reset = this.reset.bind(this);
  }

  reset() {
    this.setState({
      error: null,
      hasError: false
    });
  }

  static getDerivedStateFromError(error: unknown) {
    // Update state so the next render will show the fallback UI.
    return {
      error,
      hasError: true,
    };
  }

  componentDidMount(): void {
    Router.events.on('routeChangeComplete', this.reset);
  }

  componentWillUnmount(): void {
    Router.events.off('routeChangeComplete', this.reset);
  }

  render() {
    if (this.state.hasError && this.state.error instanceof ApiClientError) {
      return (
        <ApiClientErrorFallback error={ this.state.error } />
      );
    }
    
    if (this.state.hasError) {
      throw this.state.error;
    }

    return this.props.children;
  }
}

export default ApiClientErrorBoundary;
