import parse from 'html-react-parser';
import styled from 'styled-components';
import { fontSize } from 'styles/mixins';
import { backgroundColors, colors, grayScales } from 'styles/variables';

type ColorType = 'primary' | 'success' | 'danger' | 'warning';

type FlashMessageInterface = {
  message: string;
  type: ColorType;
  didClickCloseButton?: () => void;
}

const backgroundColor = (type: ColorType) => {
  switch(type) {
  case 'success': return backgroundColors.success;
  case 'warning': return backgroundColors.warning;
  case 'danger': return backgroundColors.danger;
  case 'primary': return backgroundColors.primary;
  }
}

const color = (type: ColorType) => {
  switch(type) {
  case 'success': return colors.success;
  case 'warning': return colors.warning;
  case 'danger': return colors.danger;
  case 'primary': return colors.primary;
  }
}

const FlashMessageStyled = styled.div.attrs((props: { type: ColorType; }) => props)`
    .flash-dialog {
      ${fontSize(16)}
      line-height: 1.5;
      width: 100%;

      &__container {
        border-radius: 4px;
        border: 2px solid ${grayScales.gs400};
        padding: 5px 64px 5px 24px;
        position: relative;

        &--close {
          cursor: pointer;
          position: absolute;
          width: 17px;
          height: 16px;
          right: 24px;
          top: 0;
          bottom: 0;
          margin: auto;

          &::before,
          &::after {
            content: '';
            width: 16px;
            height: 2px;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            background-color: ${grayScales.gs400};
          }

          &::before {
            transform: rotate(-45deg);
          }

          &::after {
            transform: rotate(45deg);
          }
        }
      }
    }

    .color {
      &__type {
        background-color: ${(props) => backgroundColor(props.type)};
        border-color: ${(props) => color(props.type)};

        &--message {
          color: ${(props) => color(props.type)};
        }

        &--close {
          &::before,
          &::after {
            background-color: ${(props) => color(props.type)};
          }
        }
      }
    }
  `,
  FlashMessage = (props: FlashMessageInterface) => (
    <FlashMessageStyled type={props.type}>
      <div className={'flash-dialog'}>
        <div className={['flash-dialog__container', 'color__type'].join(' ')}>
          <p className='color__type--message'>
            {parse(props?.message ?? '')}
          </p>
          <a
            href='#'
            className={['flash-dialog__container--close', 'color__type--close'].join(' ')}
            onClick={() => {
              props.didClickCloseButton?.()
            }}
          />
        </div>
      </div>
    </FlashMessageStyled>
  );

export default FlashMessage;
