import Modal from 'components/shareds/Modal';
import useModal from 'hooks/useModal';
import { useContext, createContext, useMemo } from 'react';

type ModalStateContextType = {
  content: JSX.Element;
  show: boolean;
}

type ModalDispatchContextType = {
  closeModal: () => void;
  openModal: () => void;
  setModalContent: (content: JSX.Element) => void;
}

// 再レンダリング防止のためにContextを分割
const ModalStateContext = createContext<ModalStateContextType>({
  content: <></>,
  show: false
});

const ModalDispatchContext = createContext<ModalDispatchContextType>({
  closeModal: () => {},
  openModal: () => {},
  setModalContent: () => {}
});

const ModalContextProvider = (props: { children: JSX.Element }) => {
  const {
    modalContent,
    show,
    openModal,
    closeModal,
    setModalContent
  } = useModal();

  const value = {
    content: modalContent,
    show
  };

  const dispatchers = useMemo(() => ({
    closeModal,
    openModal,
    setModalContent
  }), []);

  return (
    <ModalStateContext.Provider value={value}>
      <ModalDispatchContext.Provider value={ dispatchers }>
        <Modal show={show} onClose={closeModal}>
          {modalContent}
        </Modal>
        {props.children}
      </ModalDispatchContext.Provider>
    </ModalStateContext.Provider>
  );
};

const useModalState = () => useContext(ModalStateContext);

const useModalDispatch = () => useContext(ModalDispatchContext);

export default ModalStateContext;

export { ModalContextProvider, useModalState, useModalDispatch };
