import React, { useState, useContext, createContext } from 'react'

export type FlashMessageColorType = 'primary' | 'success' | 'danger' | 'warning'

export type FlashMassage = {
  color: FlashMessageColorType
  message: string
}

const flashMessageDefault: FlashMassage = {
  color: 'primary',
  message: '',
}

const FlashMessageContext = createContext({
  flashMessage: flashMessageDefault,
  setFlashMessage: (arg1: FlashMassage) => {}
})

export const FlashMessageContextProvider = (props: { children: React.ReactElement }) => {
  const [flashMessage, setFlashMessage] = useState<FlashMassage>(flashMessageDefault)
  const flashMessageState = {
    flashMessage,
    setFlashMessage
  }

  return (
    <FlashMessageContext.Provider value={flashMessageState}>
      {props.children}
    </FlashMessageContext.Provider>
  )
}

export const useFlashMessageState = () => useContext(FlashMessageContext)
