import { MaterialAudioType, MaterialTagType, MaterialType } from 'types/materialTypes';

type RequestMethod = 'GET' | 'POST' | 'PUT' | 'DELETE'

export interface ApiRequestInterface {
  getMethod: () => RequestMethod;
  getPath: (option?: object) => string;
}

// 本当であればFormDataを拡張したCustomFormDataを作りたかったが、
// 画面上で使えるFormDataとtsのFormDataが衝突してしまいレンダリング時にコケるので、
// 仕方なく元のRequestとFormをラッピングした
export class CustomFormRequest<T extends ApiRequestInterface> implements ApiRequestInterface {
  formData: FormData;
  request: T;

  constructor(params: { formData: FormData; request: T; }) {
    this.formData = params.formData;
    this.request = params.request;
  }

  getFormData() {
    Object.entries(this.request).forEach(([key, value]) => {
      this.formData?.append(key, value)
    });
    return this.formData;
  }

  getMethod(): RequestMethod {
    return 'POST'
  }

  getPath() {
    return this.request.getPath();
  }
}

type RequestVO<T> = Omit<Readonly<T>, 'getPath' | 'getMethod' | 'appendFormData'>

export class HeaderMenuRequest implements ApiRequestInterface {
  getMethod(): RequestMethod { return 'POST' }
  getPath() { return '/basic_data/header_menu' }
}

export class SideMenuRequest implements ApiRequestInterface {
  getMethod(): RequestMethod { return 'POST' }
  getPath() { return '/basic_data/side_menu' }
}

export class ElearningRequest implements ApiRequestInterface {
  getMethod(): RequestMethod { return 'POST' }
  getPath() { return '/support/elearning' }
}

export class ElearningPageRequest implements ApiRequestInterface {
  readonly elearningVideoId: number;
    
  constructor(params: RequestVO<ElearningPageRequest>) {
    this.elearningVideoId = params.elearningVideoId;
  }

  getMethod(): RequestMethod { return 'POST' }
  getPath() { return '/support/elearning/page' }
}

export class ElearningCategoryRequest implements ApiRequestInterface {
  readonly elearningId: number;
  
  constructor(params: RequestVO<ElearningCategoryRequest>) {
    this.elearningId = params.elearningId;
  }

  getMethod(): RequestMethod { return 'POST' }
  getPath() { return '/support/elearning/category' }
}

export class ArticleRequest implements ApiRequestInterface {
  readonly page: number;
  readonly pageModule: string;
  readonly pageModuleCategory: string;

  constructor(params: RequestVO<ArticleRequest>) {
    this.page = params.page;
    this.pageModule = params.pageModule;
    this.pageModuleCategory = params.pageModuleCategory;
  }

  getMethod(): RequestMethod { return 'POST' }
  getPath() { return '/article' }
}

export class ArticleHomeRequest implements ApiRequestInterface {
  getMethod(): RequestMethod { return 'POST' }
  getPath() { return '/article/home' }
}

export class ArticlePageRequest implements ApiRequestInterface {
  readonly page: number;
  readonly pageModule: string;

  constructor(params: RequestVO<ArticlePageRequest>) {
    this.page = params.page;
    this.pageModule = params.pageModule;
  }

  getMethod(): RequestMethod { return 'POST' }
  getPath() { return '/article/page' }
}

export class ForumRequest implements ApiRequestInterface {
  readonly page: number;
  readonly category: string;
  readonly limit: number;

  constructor(params: RequestVO<ForumRequest>) {
    this.page = params.page;
    this.category = params.category;
    this.limit = params.limit;
  }

  getMethod(): RequestMethod { return 'POST' }
  getPath() { return '/forum' }
}

export class ForumTopicRequest implements ApiRequestInterface {
  readonly page: number;
  readonly category: string;
  readonly topicId: string;

  constructor(params: RequestVO<ForumTopicRequest>) {
    this.page = params.page;
    this.category = params.category;
    this.topicId = params.topicId;
  }

  getMethod(): RequestMethod { return 'POST' }
  getPath() { return '/forum_topic' }
}

export class ForumTopicCreateRequest implements ApiRequestInterface {
  readonly title: string;
  readonly body: string;
  readonly forumCategory: string;

  constructor(params: RequestVO<ForumTopicCreateRequest>) {
    this.title = params.title;
    this.body = params.body;
    this.forumCategory = params.forumCategory;
  }

  getMethod(): RequestMethod { return 'POST' }
  getPath() { return '/forum_topic/create' }
}

export class ForumTopicFavoriteRequest implements ApiRequestInterface {
  readonly forumTopicId: number;

  constructor(params: RequestVO<ForumTopicFavoriteRequest>) {
    this.forumTopicId = params.forumTopicId;
  }

  getMethod(): RequestMethod { return 'POST' }
  getPath() { return '/forum_topic/favorite' }
}

export class ForumCommentRequest implements ApiRequestInterface {
  readonly commentId: number;

  constructor(params: RequestVO<ForumCommentRequest>) {
    this.commentId = params.commentId;
  }

  getMethod(): RequestMethod { return 'POST' }
  getPath() { return '/forum_comment' }
}

export class ForumCommentRequestParams {
  readonly comment: string;
  readonly commentId: number;
  readonly forumCategory: string;
  readonly topicId: number;

  constructor(params: Readonly<ForumCommentRequestParams>) {
    this.comment = params.comment;
    this.commentId = params.commentId;
    this.forumCategory = params.forumCategory;
    this.topicId = params.topicId;
  }
}

export class ForumCommentCreateRequest extends ForumCommentRequestParams implements ApiRequestInterface {
  constructor(params: RequestVO<ForumCommentRequestParams>) {
    super(params)
  }

  getMethod(): RequestMethod { return 'POST' }
  getPath() { return '/forum_comment/create' }
}

export class ForumCommentUpdateRequest extends ForumCommentRequestParams implements ApiRequestInterface {
  constructor(params: RequestVO<ForumCommentRequestParams>) {
    super(params)
  }

  getMethod(): RequestMethod { return 'POST' }
  getPath() { return '/forum_comment/update' }
}

export class ForumCommentDeleteRequest extends ForumCommentRequestParams implements ApiRequestInterface {
  constructor(params: RequestVO<ForumCommentRequestParams>) {
    super(params)
  }

  getMethod(): RequestMethod { return 'POST' }
  getPath() { return '/forum_comment/delete' }
}

export class OfferRequest implements ApiRequestInterface {
  readonly page: number;
  readonly type: string

  constructor(params: RequestVO<OfferRequest>) {
    this.page = params.page;
    this.type = params.type;
  }

  getMethod(): RequestMethod { return 'POST' }
  getPath() { return '/offer' }
}

export class OfferPageRequest implements ApiRequestInterface {
  readonly id: number;

  constructor(params: RequestVO<OfferPageRequest>) {
    this.id = params.id;
  }

  getMethod(): RequestMethod { return 'POST' }
  getPath() { return '/offer/page' }
}

export class OfferApplyRequest implements ApiRequestInterface {
  readonly offerId: number;

  constructor(params: RequestVO<OfferApplyRequest>) {
    this.offerId = params.offerId;
  }

  getMethod(): RequestMethod { return 'POST' }
  getPath() { return '/offer/apply' }
}

export class OfferMaterialRequest implements ApiRequestInterface {
  readonly id: number;

  constructor(params: RequestVO<OfferMaterialRequest>) {
    this.id = params.id;
  }

  getMethod(): RequestMethod { return 'POST' }
  getPath() { return '/offer/material' }
}

export class TopOfferRequest implements ApiRequestInterface {
  readonly page: number;

  constructor(params: RequestVO<TopOfferRequest>) {
    this.page = params.page;
  }

  getMethod(): RequestMethod { return 'POST' }
  getPath() { return '/top_offer' }
}

export class TopOfferPageRequest implements ApiRequestInterface {
  readonly id: number;

  constructor(params: RequestVO<TopOfferPageRequest>) {
    this.id = params.id;
  }

  getMethod(): RequestMethod { return 'POST' }
  getPath() { return '/top_offer/page' }
}

export class TopOfferDownloadPdfRequest implements ApiRequestInterface {
  readonly id: number;

  constructor(params: RequestVO<TopOfferDownloadPdfRequest>) {
    this.id = params.id;
  }

  getMethod(): RequestMethod { return 'POST' }
  getPath() { return '/top_offer/download_pdf' }
}

export class TrendRequest implements ApiRequestInterface {
  getMethod(): RequestMethod { return 'POST' }
  getPath() { return '/trend' }
}

export class ArticleCustomFormEntryRequest implements ApiRequestInterface { 
  readonly pageId: number;
  readonly path: string;

  constructor(params: RequestVO<ArticleCustomFormEntryRequest>) {
    this.pageId = params.pageId;
    this.path = params.path;
  }

  getMethod(): RequestMethod { return 'POST' }
  getPath() { return '/article/form_entry/create' }
}

export class ChannelInfoRequest implements ApiRequestInterface {
  getMethod(): RequestMethod { return 'POST' }
  getPath() { return '/channel_info' }
}

export class DashboardRequest implements ApiRequestInterface {
  getMethod(): RequestMethod { return 'POST' }
  getPath() { return '/dashboard' }
}

export class EventRequest implements ApiRequestInterface {
  getMethod(): RequestMethod { return 'POST' }
  getPath() { return '/event' }
}

export class EventCalendarRequest implements ApiRequestInterface {
  readonly date: string;

  constructor(params: RequestVO<EventCalendarRequest>) {
    this.date = params.date;
  }

  getMethod(): RequestMethod { return 'POST' }
  getPath() { return '/event/calendar' }
}

export class MessageRequest implements ApiRequestInterface {
  readonly page: number;

  constructor(params: RequestVO<MessageRequest>) {
    this.page = params.page;
  }

  getMethod(): RequestMethod { return 'POST' }
  getPath() { return '/message' }
}

export class MessagePageRequest implements ApiRequestInterface {
  readonly page: number;

  constructor(params: RequestVO<MessagePageRequest>) {
    this.page = params.page;
  }

  getMethod(): RequestMethod { return 'POST' }
  getPath() { return '/message/page' }
}

export class SettingsProfileRequest implements ApiRequestInterface {
  getMethod(): RequestMethod { return 'POST' }
  getPath() { return '/settings/profile' }
}

// TODO: カラムの構造をそのまま持っているのでスネークケースだが、余裕があれば直す
export class SettingsProfileUpdateRequest implements ApiRequestInterface {
  readonly appeal_point: string;
  readonly boast_of_video_url: string;
  readonly catchphrase: string;
  readonly equipment_json: {
    readonly camera: string[];
    readonly os: string[];
  }
  readonly favorite_youtuber: string;
  readonly goal: string;
  readonly hope_collaboration: number;
  readonly member_json: {
    readonly child_count: number;
    readonly female_count: number;
    readonly male_count: number;
    readonly parent_count: number;
    readonly remarks: string;
    readonly structure: string;
    readonly structure_other: string;
  }
  readonly nickname: string;
  readonly show_face: number;
  readonly software_json: {
    readonly android: string[];
    readonly ios: string[];
    readonly mac: string[];
    readonly other: string;
    readonly software: string[];
    readonly windows: string[];
  }

  constructor(params: RequestVO<SettingsProfileUpdateRequest>) {
    this.appeal_point = params.appeal_point;
    this.boast_of_video_url = params.boast_of_video_url;
    this.catchphrase = params.catchphrase;
    this.equipment_json = params.equipment_json;
    this.favorite_youtuber = params.favorite_youtuber;
    this.goal = params.goal;
    this.hope_collaboration = params.hope_collaboration;
    this.member_json = params.member_json;
    this.nickname = params.nickname;
    this.show_face = params.show_face;
    this.software_json = params.software_json;
  }

  getMethod(): RequestMethod { return 'POST' }
  getPath() { return '/settings/profile/update' }
}

export class SettingsNotificationRequest implements ApiRequestInterface {
  getMethod(): RequestMethod { return 'POST' }
  getPath() { return '/settings/notification' }
}

export class SettingsNotificationUpdateRequest implements ApiRequestInterface {
  readonly mail_notice_json: {
    readonly forum: number;
    readonly news: number;
  }

  constructor(params: RequestVO<SettingsNotificationUpdateRequest>) {
    this.mail_notice_json = params.mail_notice_json;
  }

  getMethod(): RequestMethod { return 'POST' }
  getPath() { return '/settings/notification/update' }
}

export class SettingsNotificationPushNotificationUpdateRequest implements ApiRequestInterface {
  readonly token: string | null;

  constructor(params: RequestVO<SettingsNotificationPushNotificationUpdateRequest>) {
    this.token = params.token;
  }

  getMethod(): RequestMethod { return 'POST' }
  getPath() { return '/settings/push_notice_token/update' }
}

export class TermsRequest implements ApiRequestInterface {
  getMethod(): RequestMethod { return 'POST' }
  getPath() { return '/term' }
}

export class TermsLatestRequest implements ApiRequestInterface {
  getMethod(): RequestMethod { return 'POST' }
  getPath() { return '/term/latest' }
}

export class TermsAgreeRequest implements ApiRequestInterface {
  readonly termsIdList: number[];

  constructor(params: RequestVO<TermsAgreeRequest>) {
    this.termsIdList = params.termsIdList;
  }

  getMethod(): RequestMethod { return 'POST' }
  getPath() { return '/term/agree' }
}

export class UmumagaRequest implements ApiRequestInterface {
  readonly page: number;

  constructor(params: RequestVO<UmumagaRequest>) {
    this.page = params.page;
  }

  getMethod(): RequestMethod { return 'POST' }
  getPath() { return '/umumaga' }
}

export class UmumagaPageRequest implements ApiRequestInterface {
  readonly page: number;

  constructor(params: RequestVO<UmumagaPageRequest>) {
    this.page = params.page;
  }

  getMethod(): RequestMethod { return 'POST' }
  getPath() { return '/umumaga/page' }
}

export class MvpRequest implements ApiRequestInterface {
  readonly page: number;

  constructor(params: RequestVO<MvpRequest>) {
    this.page = params.page;
  }

  getMethod(): RequestMethod { return 'POST' }
  getPath() { return '/mvp' }
}

export class MvpPageRequest implements ApiRequestInterface {
  readonly page: number;

  constructor(params: RequestVO<MvpPageRequest>) {
    this.page = params.page;
  }

  getMethod(): RequestMethod { return 'POST' }
  getPath() { return '/mvp/page' }
}

export class ContestRequest implements ApiRequestInterface {
  readonly page: number;

  constructor(params: RequestVO<ContestRequest>) {
    this.page = params.page;
  }

  getMethod(): RequestMethod { return 'POST' }
  getPath() { return '/contest' }
}

export class ContestPageRequest implements ApiRequestInterface {
  readonly page: number;
  readonly contestId: number;
  readonly order: string;

  constructor(params: RequestVO<ContestPageRequest>) {
    this.page = params.page;
    this.contestId = params.contestId;
    this.order = params.order;
  }

  getMethod(): RequestMethod { return 'POST' }
  getPath() { return '/contest/page' }
}

export class ContestEntryVideoVoteRequest implements ApiRequestInterface {
  readonly entryVideoId: number;

  constructor(params: RequestVO<ContestEntryVideoVoteRequest>) {
    this.entryVideoId = params.entryVideoId;
  }

  getMethod(): RequestMethod { return 'POST' }
  getPath() { return '/contest_entry_video/vote' }
}

export class ContestEntryVideoPostRequest implements ApiRequestInterface {
  readonly contestId: number;
  readonly entryVideoUrl: string;

  constructor(params: RequestVO<ContestEntryVideoPostRequest>) {
    this.contestId = params.contestId;
    this.entryVideoUrl = params.entryVideoUrl;
  }

  getMethod(): RequestMethod { return 'POST' }
  getPath() { return '/contest_entry_video/post' }
}

export class ContestEntryVideoDeleteRequest implements ApiRequestInterface {
  readonly entryVideoId: number;

  constructor(params: RequestVO<ContestEntryVideoDeleteRequest>) {
    this.entryVideoId = params.entryVideoId;
  }

  getMethod(): RequestMethod { return 'POST' }
  getPath() { return '/contest_entry_video/delete' }
}

export class OfferSubmitVideoRequest implements ApiRequestInterface {
  readonly id: number;
  readonly videoId: string;

  constructor(params: RequestVO<OfferSubmitVideoRequest>) {
    this.id = params.id;
    this.videoId = params.videoId;
  }

  getMethod(): RequestMethod { return 'POST' }
  getPath() { return '/offer/submit_video' }
}

export class OfferDownloadMaterialRequest implements ApiRequestInterface {
  readonly offerMaterialId: number;

  constructor(params: RequestVO<OfferDownloadMaterialRequest>) {
    this.offerMaterialId = params.offerMaterialId;
  }

  getMethod(): RequestMethod { return 'POST' }
  getPath() { return '/offer/download_material' }
}

export class OfferAgreementTermsRequest implements ApiRequestInterface {
  getMethod(): RequestMethod { return 'POST' }
  getPath() { return '/offer/agreement_terms' }
}

export class ApplySubmitMonetizationRequest implements ApiRequestInterface {
  readonly uploadFile: File | null;

  constructor(params: RequestVO<ApplySubmitMonetizationRequest>) {
    this.uploadFile = params.uploadFile;
  }

  getMethod(): RequestMethod { return 'POST' }
  getPath() { return '/apply/submit_monetize' }
}

export class AliasRequest implements ApiRequestInterface {
  readonly url: string;

  constructor(params: RequestVO<AliasRequest>) {
    this.url = params.url;
  }

  getMethod(): RequestMethod { return 'POST' }
  getPath() { return '/alias' }
}

export class AdsenseRequest implements ApiRequestInterface {
  readonly page: number;

  constructor(params: RequestVO<AdsenseRequest>) {
    this.page = params.page;
  }

  getMethod(): RequestMethod { return 'POST' }
  getPath() { return '/adsense' }
}

export class AdsenseReportRequest implements ApiRequestInterface {
  readonly targetMonth: string | null;

  constructor(params: RequestVO<AdsenseReportRequest>) {
    this.targetMonth = params.targetMonth;
  }

  getMethod(): RequestMethod { return 'POST' }
  getPath() { return '/adsense/report' }
}

export class InformationRequest implements ApiRequestInterface {
  getMethod(): RequestMethod { return 'POST' }
  getPath() { return '/information' }
}

export class AudioPreviewRequest implements ApiRequestInterface {
  readonly id: number;

  constructor(params: RequestVO<AudioPreviewRequest>) {
    this.id = params.id;
  }

  getMethod(): RequestMethod { return 'GET' }
  getPath() { return `/audios/${this.id}/preview` }
}

/**
 * 素材
 */

export class MaterialDownloadRequest implements ApiRequestInterface {
  readonly id: number;
  readonly type: MaterialType;

  constructor(params: RequestVO<MaterialDownloadRequest>) {
    this.id = params.id;
    this.type = params.type;
  }

  getMethod(): RequestMethod { return 'GET' }
  getPath() { return `/downloads/${this.id}/${this.type}` }
}

export class MaterialAudiosRequest implements ApiRequestInterface {
  readonly category: string;
  readonly page?: number;
  readonly source?: string;
  readonly tags?: number[];
  readonly keyword?: string;

  constructor(params: RequestVO<MaterialAudiosRequest>) {
    this.category = params.category;
    this.page = params.page;
    this.source = params.source;
    this.tags = params.tags;
    this.keyword = params.keyword;
  }

  getMethod(): RequestMethod { return 'GET' }
  getPath() { return '/audios' }
}

export class AudioKeywordSuggestRequest implements ApiRequestInterface {
  readonly keyword: string;
  readonly category: string;

  constructor(params: RequestVO<AudioKeywordSuggestRequest>) {
    this.keyword = params.keyword;
    this.category = params.category;
  }

  getMethod(): RequestMethod { return 'GET' }
  getPath() { return `/audios/keyword_suggest` }
}

export class MaterialAudiosListTagAndSourceRequest implements ApiRequestInterface {
  readonly category: MaterialAudioType;

  constructor(params: RequestVO<MaterialAudiosListTagAndSourceRequest>) {
    this.category = params.category;
  }

  getMethod(): RequestMethod { return 'GET' }
  getPath() { return `/audios/list_tags_and_source/${this.category}` }
}

export class MaterialImageRequest implements ApiRequestInterface {
  readonly id: string;

  constructor(params: RequestVO<MaterialImageRequest>) {
    this.id = params.id;
  }

  getMethod(): RequestMethod { return 'GET' }
  getPath() { return `/images/${this.id}` }
}

export class MaterialImagesRequest implements ApiRequestInterface {
  readonly keyword?: string;
  readonly page?: number;
  readonly itemType?: string;

  constructor(params: RequestVO<MaterialImagesRequest>) {
    this.keyword = params.keyword;
    this.page = params.page;
    this.itemType = params.itemType;
  }

  getMethod(): RequestMethod { return 'GET' }
  getPath() { return '/images' }
}

export class MaterialOtherItemsRequest implements ApiRequestInterface {
  readonly category: string;
  readonly page?: number;

  constructor(params: RequestVO<MaterialAudiosRequest>) {
    this.category = params.category;
    this.page = params.page;
  }

  getMethod(): RequestMethod { return 'GET' }
  getPath() { return '/other_items/' + this.category }
}

export class MaterialDownloadHistoryRequest implements ApiRequestInterface {
  readonly page: number;

  constructor(params: RequestVO<MaterialDownloadHistoryRequest>) {
    this.page = params.page;
  }

  getMethod(): RequestMethod { return 'GET' }
  getPath() { return '/downloads' }
}

export class MaterialReportRequest implements ApiRequestInterface {
  readonly downloadId: number;
  readonly videoUrl: string;

  constructor(params: RequestVO<MaterialReportRequest>) {
    this.downloadId = params.downloadId;
    this.videoUrl = params.videoUrl;
  }

  getMethod(): RequestMethod { return 'POST' }
  getPath() { return `/downloads/${this.downloadId}/report` }
}
