import LogoCreasHeader from 'components/atoms/logo/LogoCreasHeader';
import Layout from 'components/layouts/Layout';
import styled, { css } from 'styled-components';
import { mediaDown } from 'styles/mixins';
import { backgroundColors, colors } from 'styles/variables';

const ErrorLayoutStyled = styled.div`
    .login-layout {
      background-color: ${backgroundColors.error};
      box-sizing: border-box;
      height: 100%;
      position: absolute;
      width: 100%;

      &__container {
        box-sizing: border-box;
        height: 100%;
        position: relative;
        width: 100%;

        &--header {
          .header-content {
            ${mediaDown('desktop', css`
              height: 48px;
              padding: 10px;
            `)}

            align-items: center;
            background-color: ${colors.white};
            box-shadow: 0 0 4px 0 rgb(0 0 0 / 10%);
            display: flex;
            height: 64px;
            padding: 0 40px 0 80px;
          }
        }

        &--body {
          align-items: center;
          display: flex;
          justify-content: center;
          min-height: 480px;
          width: 100%;
        }

        &--footer {
        }
      }
    }
  `,
  ErrorLayout: React.FC<{ children?: React.ReactNode }> = (props): React.ReactElement => (
    <Layout>
      <ErrorLayoutStyled>
        <div className='login-layout'>
          <div className='login-layout__container'>
            <header className='login-layout__container--header'>
              <div className='header-content'>
                <LogoCreasHeader />
              </div>
            </header>

            <main className='login-layout__container--body'>{props.children}</main>

            <footer className='login-layout__container--footer'></footer>
          </div>
        </div>
      </ErrorLayoutStyled>
    </Layout>
  );

export default ErrorLayout;
